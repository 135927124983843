export interface DtoUserSession {
  api_token: string,
  email: string,
  first_name: string,
  group_account_id: number,
  id: number,
  last_name: string,
  modules: Array<'territory_intel' | 'tenders'>
}

export class UserSession {
  id: number;
  groupAccountId: number;
  firstName: string;
  lastName: string;
  email: string;
  apiToken: string;
  modules: Array<'territory_intel' | 'tenders'>;

  constructor(userSession: DtoUserSession) {
    this.id = userSession.id;
    this.groupAccountId = userSession.group_account_id;
    this.firstName = userSession.first_name;
    this.lastName = userSession.last_name;
    this.email = userSession.email;
    this.apiToken = userSession.api_token;
    this.modules = userSession.modules;
  }
}

export interface DbUser {

  last_name: string;
  first_name: string;
}

export class User {
  firstName: string;
  lastName: string;

  constructor(dbUser: DbUser) {
    this.firstName = dbUser.first_name;
    this.lastName = dbUser.last_name;
  }
}
