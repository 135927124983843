import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SharedDocument } from '../../../models/share/share';
import { ExplainModuleEnum } from "../../../shared/services/module-manager.service";

@Component({
  selector: 'app-expandable-share-panel',
  templateUrl: './expandable-share-panel.component.html',
  styleUrls: ['./expandable-share-panel.component.scss']
})
export class ExpandableSharePanelComponent {
  @Input({required: true}) document!: SharedDocument;
  @Input() withExportPanel: boolean = false;
  @Output() toggle = new EventEmitter<boolean>();
  protected readonly ExplainModuleEnum = ExplainModuleEnum;
}
