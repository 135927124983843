import { Injectable, TemplateRef } from '@angular/core';
import { saveAs } from 'file-saver';
import { ApiExportService } from './api/export-download/api-export.service';
import { ToastMessageStackService } from './toast-message-stack.service';
import { HttpHeaders, HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
/**
 * Class taking care of exporting files
 * Uses npm library FILE-SAVER, to save document onto user's computer.
 */
export class ExportService {

  constructor(private apiExportService: ApiExportService, private toastMessageStackService: ToastMessageStackService) { }

  export(
    requestBody: any,
    toast?: {template: TemplateRef<any>, options?: object},
    options?: {timeout?: number, generationHeaders?: HttpHeaders, downloadHeaders?: HttpHeaders}
  ) {
    if (toast) this.toastMessageStackService.show(toast.template, toast.options);
    let allow = true;
    return {
      promise: new Promise<void>(async (resolve, reject) => {
        let start = Date.now();
        const url = await this.apiExportService.generateUrl(requestBody, {timeout: options?.timeout, headers: options?.generationHeaders});
        let blob;
        while(start + (options?.timeout ?? 60000) - Date.now() > 0 && !blob && allow) {
          blob = await this.apiExportService.downloadBlob(url, {headers: options?.downloadHeaders}).catch(() => undefined);
          await new Promise(r => setTimeout(r, 1000));
        }
        if (blob) {
          saveAs(blob, this.getFileNameFromURL(url) ?? undefined);
          resolve();
        }
        reject();
      }).finally(() => {
        if (toast) {
          this.toastMessageStackService.removeByClassName((toast.options as any)?.classname);
          this.toastMessageStackService.removeByClassName(`l-t-${(toast.options as any)?.id}`)
        }
      }),
      cancel: () => {allow = false}
    };
  }

  /**
   * Method to save Blob as a PDF to the user's computer.
   */
  savePdfToComputer(adminDocPdfBlob: Blob, documentName: string): void {
    saveAs(adminDocPdfBlob, documentName);
  }

  getFileNameFromURL(url: string): string | null {
    const urlObject = new URL(url);
    const pathParts = urlObject.pathname.split('/');
    return decodeURIComponent(pathParts[pathParts.length - 1]);
  }

  saveDceToComputer(response: HttpResponse<Blob>) {
    if (!response.body) return;
    const url = window.URL.createObjectURL(response.body);
    const a = document.createElement('a');
    let fileName = 'DCE.zip';
    const contentDisposition = response.headers.get('content-disposition');
    if (contentDisposition) {
      console.log(contentDisposition);
      // Extraction du nom de fichier
      const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
      if (matches?.[1]) {
        fileName = matches?.[1].replace(/['"]/g, '');
        // Décode les caractères spéciaux si nécessaire
        fileName = decodeURIComponent(fileName);
      }
    }
    a.href = url;
    a.download = fileName; // nom par défaut du fichier
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }
}
