import { SortDirEnum } from '../../common-explain/models/sort';

export interface DbPostTenderSearchBody extends DbPostPaginatedSearch, DbPostSortedSearch {
  user_id?: number;
  user_token: string;
  filters?: DbPostFilter | DbPostWatchFilter;
  service?: ApiSearchServiceEnum;
}
export interface DbFilterPeriod {
  from: string;
  to: string;
}

/** méthode de création du corps de recherche du endpoints POST /tenders */
export class PostTenderSearchBody implements DbPostTenderSearchBody {
  user_token: string;
  user_id: number;
  filters: DbPostFilter;
  service = ApiSearchServiceEnum.SEARCH_TOPICS;
  sort = {dir: SortDirEnum.DESC, field: SortFieldEnum.INDEXATION_DATE};
  limit = 25;
  offset = 0;

  constructor(
    topics: number[] | undefined,
    text: string | undefined,
    refinedText: string | undefined,
    territories: string[],
    indexation_period: DbFilterPeriod | undefined,
    estimated_end_period: DbFilterPeriod | undefined,
    tender_types: TenderTypesEnum[] | undefined,
    status_ids: Array<number | null> | undefined,
    assigned_user_ids: number[] | undefined,
    offset: number,
    limit: number,
    sort: { dir: SortDirEnum; field: SortFieldEnum },
  ) {
    this.user_token = (localStorage.getItem('api_token') ?? '');
    this.user_id = +(localStorage.getItem('user_id') ?? 0);
    this.filters = {
      topics: topics,
      text: text,
      refined_search_text: refinedText,
      indexation_period: indexation_period,
      estimated_end_period: estimated_end_period,
      tender_types,
      status_ids,
      assigned_user_ids
    };
    this.filters['territories'] = territories;
    this.offset = offset;
    this.limit = limit;
    this.sort = sort;
    if (status_ids) {
      this.service = ApiSearchServiceEnum.SEARCH_QUALIFIED_TENDERS;
    } else if (text) {
      this.service = ApiSearchServiceEnum.SEARCH_TEXT;
    }
  }
}

export class PostTenderSearchWatchBody implements DbPostTenderSearchBody {
  user_token: string;
  user_id: number;
  filters: DbPostWatchFilter;
  service = ApiSearchServiceEnum.SEARCH_MARKET_WATCH_TENDERS;
  sort = {dir: SortDirEnum.DESC, field: SortFieldEnum.INDEXATION_DATE};
  limit = 25;
  offset = 0;

  constructor(
    topics: number[] | undefined,
    market_watch_id: number | undefined,
    market_watch_date: string | undefined,
    offset: number,
    limit: number,
    sort: { dir: SortDirEnum; field: SortFieldEnum },
  ) {
    this.user_token = (localStorage.getItem('api_token') ?? '');
    this.user_id = +(localStorage.getItem('user_id') ?? 0);
    this.filters = {
      topics: topics,
      market_watch_id: market_watch_id,
      market_watch_date: market_watch_date,
    };
    this.offset = offset;
    this.limit = limit;
    this.sort = sort;
  }
}

export interface DbPostFilter {
  territories?: string[];
  indexation_period?: DbFilterPeriod;
  estimated_end_period?: DbFilterPeriod;
  topics?: number[];
  text?: string;
  refined_search_text?: string;
  tender_types?: TenderTypesEnum[];
  status_ids?: Array<number | null>;
  assigned_user_ids?: number[];
}

export interface DbPostWatchFilter {
  topics?: number[],
  market_watch_id?: number;
  market_watch_date?: string;
}

export enum TenderTypesEnum {
  PRE_INFORMATION = 'ao_avis_pre_info',
  INITIAL = 'ao_avis_initial',
  RECTIFICATIF = 'ao_avis_rectificatif',
  ATTRIBUTION = 'ao_avis_attribution',
}

export enum ApiSearchServiceEnum {
  DOCUMENTS = 'documents',
  TENDERS = 'tenders',
  SEARCH_TOPICS= 'search-topics',
  SEARCH_TEXT= 'search-text',
  SEARCH_MARKET_WATCH_TENDERS= 'search-market-watch-tenders',
  SEARCH_QUALIFIED_TENDERS = 'search-qualified-tenders',
  COUNT_QUALIFIED_TENDERS = 'count-qualified-tenders'
}

export enum SortFieldEnum {
  RELEVANCE = '_score',
  INDEXATION_DATE = 'indexation_date',
  CLOSING_DATE = 'closing_date',
  ESTIMATED_END_DATE = 'estimated_end_date',
}

export interface DbPostSearchSort {
  dir: SortDirEnum;
  field: SortFieldEnum;
}

export interface DbPostPaginatedSearch {
  limit?: number;
  offset?: number;
}

export interface DbPostSortedSearch {
  sort?: { dir: SortDirEnum, field: SortFieldEnum };
}
