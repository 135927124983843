import { firstValueFrom } from 'rxjs';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GridQuestion, GridQuestionSectionEnum, GridQuestionTypeEnum } from '../../models/grid-question';
import { DceDocument } from '../../models/dce-document';
import { UserTrackerService } from '../../../shared/services/tracking/user-tracker.service';
import { EventTypeName } from '../../../models/user-tracker';
import { GridAnswer, TextItem } from '../../models/grid-answer';
import { ChunkDetail } from '../../models/chunk-detail';
import { SmartGridService } from '../../services/smart-grid.service';

@Component({
  selector: 'app-tender-smart-grid',
  templateUrl: './tender-smart-grid.component.html',
  styleUrls: ['./tender-smart-grid.component.scss']
})
export class TenderSmartGridComponent {
  protected readonly GridQuestionTypeEnum = GridQuestionTypeEnum;
  protected readonly GridQuestionSectionEnum = GridQuestionSectionEnum;

  @Input() tenderId!: string;
  @Input() tenderDceProjectUid!: boolean;
  @Input() dceDocuments: DceDocument[] = [];
  @Input() clickedAnswer: GridAnswer | null = null;
  @Input() clickedRef: ChunkDetail | null = null;

  @Output() editQuestionTrigger = new EventEmitter<GridQuestion>();
  @Output() addQuestionTrigger = new EventEmitter<void>();
  @Output() clickedRefChange = new EventEmitter<ChunkDetail | null>();

  showLoader = true;
  isCopied = false;
  clickedRefIndex: number | undefined = undefined;
  clickedQuestion: GridQuestion | null = null;
  questionHovered: number | null = null;
  questionWithInfoOpened: GridQuestion | null = null;


  constructor(
    private userTrackerService: UserTrackerService,
    public smartGridService: SmartGridService
  ) {
  }

  async onClickQuestion(question: GridQuestion, scrollToQuestion = false) {
    this.clickedRefIndex = undefined;
    this.clickedRefChange.emit(null);
    if (this.clickedQuestion?.questionId === question.questionId) {
      this.clickedQuestion = null;
    } else {
      this.clickedQuestion = question;

      this.trackEvent({is_question_selected: this.clickedAnswer !== question.answer, question: question},
        EventTypeName.TENDER_GRID_QUESTION_CLICKED);
    }
    if (scrollToQuestion) {
      setTimeout(() => {
        const element = document.getElementById(question.questionId.toString());
        if (element) {
          element.scrollIntoView({behavior: 'smooth', block: 'center'});
        }
      }, 100);
    }

    if (question.updated_at !== null && !question.answer) {
      const updatedQuestion = await this.smartGridService.fetchAnswer(question);
      question = updatedQuestion;
      this.smartGridService.updateUnpinnedQuestion(updatedQuestion);

    }

    if (question.answer) {
      this.clickedRefIndex = undefined;
      const ref = Object.keys(question.answer.chunkDetails)[0];
      this.isCopied = false;
      this.clickedRefChange.emit(this.clickedRef === question.answer.chunkDetails[ref] ? null : question.answer.chunkDetails[ref]);
    }
  }

  onClickRef(item: TextItem, question: GridQuestion) {
    if (this.clickedRefIndex !== item.index) {
      this.clickedRefIndex = item.index;
    } else {
      this.clickedRefIndex = undefined;
    }
    if (question.answer && item.ref) {
      this.clickedRefChange.emit(
        this.clickedRef === question.answer.chunkDetails[item.ref] && this.clickedRefIndex == undefined
          ? null : question.answer.chunkDetails[item.ref]);
    }

  }

  checkIfQuestionIsSelected(question: GridQuestion) {
    return question.questionId === this.clickedQuestion?.questionId && this.clickedRefIndex == undefined;
  }

  copyToClipboard(event: any, question: GridQuestion) {
    event.stopPropagation();
    navigator.clipboard.writeText(question.displayedName + ' :\n' + question.answer?.text);
    this.isCopied = true;
    setTimeout(() => {
        this.isCopied = false;
      }
      , 500);
  }

  copyNameToClipboard(event: any, name: string) {
    event.stopPropagation();
    navigator.clipboard.writeText(name);
    this.isCopied = true;
    setTimeout(() => {
        this.isCopied = false;
      }
      , 500);
  }

  trackEvent(data = {}, eventName = '') {
    firstValueFrom(this.userTrackerService.track({
      event_type: eventName,
      event_timestamp: (new Date()).toISOString(),
      ...this.userTrackerService.buildBasicUserInformations(),
      tender_id: this.dceDocuments[0].rawKey.split('/')[3],
      ...data
    }));
  }

  trackByQuestionId(index: number, item: GridQuestion) {
    return item.questionId;
  }

  editQuestion(question: GridQuestion) {
    this.editQuestionTrigger.emit(question);
  }

  onClickAddQuestion() {
    this.addQuestionTrigger.emit();
  }

  async onClickOpenMoreInfo(question: GridQuestion, type: GridQuestionSectionEnum) {
    await this.fetchQuestionInfo(question, type);
    this.questionWithInfoOpened = question;
  }

  async fetchQuestionInfo(question: GridQuestion, type: GridQuestionSectionEnum) {
    await this.smartGridService.fetchQuestionInfo(question, type);
  }


  async pinQuestion(question: GridQuestion) {
    this.questionHovered = null;
    if (this.smartGridService.pinnedQuestions.length < this.smartGridService.MAX_PINNED_QUESTIONS) {
      await this.smartGridService.pinQuestion(question);
    }
  }

  async unpinQuestion(question: GridQuestion) {
    this.questionHovered = null;
    await this.smartGridService.unpinQuestion(question);
  }

  checkIfOwnerOfQuestion(question: GridQuestion) {
    const user_id = localStorage.getItem('user_id');
    if (user_id) {
      return question.user_id === parseInt(user_id);
    }
    return false;
  }

}
