import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { DtoUserSession, User, UserSession } from '../../../../core/user/user.model';
import { HeadersService } from '../headers/headers.service';


@Injectable({
  providedIn: 'root'
})
export class ApiAccountService {

  constructor(private http: HttpClient,
              private headerService: HeadersService) {
  }

  /**
   * Log the user in, and sets its session values inside the local storage.
   */
  getUserSession(logs: { email: string | null, password: string }) {
    return this.http.post<DtoUserSession>(`${environment.explainApiUrl}account/session`, logs)
      .pipe(
        map((user) => {
          return new UserSession(user);
        }));
  }

  getUserInfo(user_id: number) {
    return this.http.post<any>(`${environment.explainApiUrl}user`,
      {
        'fields': [
          'first_name',
          'last_name',
        ],
        'filters': {
          'id': user_id
        }
      },
      this.headerService.httpHeaders)
      .pipe(
        map((users) => {
          if (users['data'].length === 0) {
            return null;
          }
          return new User(users['data'][0])
        }));
  }

}
