import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {HeadersService} from '../headers/headers.service';
import { lastValueFrom, timeout } from 'rxjs';
import {EventTypeName} from "../../../../models/user-tracker";
import {environment} from "../../../../../environments/environment";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ApiExportService {

  constructor(private http: HttpClient,
              private headerService: HeadersService) {
  }

  generateUrl(body: object, options?: {timeout?: number, headers?: HttpHeaders}): Promise<string> {
    return lastValueFrom(this.http.post<{ s3_download_doc_url: string, errorMessage?: string }>(
      `${environment.explainApiGatewayUrl}export-document`,
      body,
      { headers: options?.headers }
    ).pipe(
      timeout(options?.timeout ?? 60000),
      map(res => {
        if (res.errorMessage) throw new Error(res.errorMessage);
        return res.s3_download_doc_url;
      })
    ));
  }

  downloadBlob(url: string, options?: {headers?: HttpHeaders}): Promise<Blob> {
    return lastValueFrom(this.http.get(url, { headers: options?.headers, responseType: 'blob' }))
  }

  getFileBlob(projectUid: string, docUid?: string){
    return this.http.get(
      `${environment.explainDceExportWorkerUrl}?project_uid=${projectUid}${docUid ? ("&doc_uid=" + docUid) : ""}`,
      {
        headers: this.headerService.getTrackedHttpHeaders(EventTypeName.DCE_DOWNLOAD_TRIGGERED).headers,
        observe: 'response',  // Pour avoir accès aux headers
        responseType: 'blob'
      }
    )
  }

}
