import { DbGridAnswer, GridAnswer } from './grid-answer';
import { DbDceDocumentUid } from './dce-document-uid';


export interface DbGridQuestionWithAnswer {
  project_uid: string;
  question_id: number;
  displayed_name: string;
  name: string;
  documents_uids: DbDceDocumentUid[];
  answer: DbGridAnswer | null;
  is_generation_finished: boolean;
  is_generation_failed: boolean;
}

export class GridQuestionWithAnswer {
  questionId: number;
  displayedName: string;
  name: string;
  isGenerationFinished: boolean;
  isGenerationFailed: boolean;
  updated_at: string | null = null;
  answer: GridAnswer | null;
  user_id: number | null = null;

  constructor(dbGridQuestion: DbGridQuestionWithAnswer) {

    this.questionId = dbGridQuestion.question_id;
    this.displayedName = dbGridQuestion.displayed_name;
    this.name = dbGridQuestion.name;
    this.isGenerationFailed = dbGridQuestion.is_generation_failed;
    this.isGenerationFinished = dbGridQuestion.is_generation_finished;
    this.answer = dbGridQuestion.answer ? new GridAnswer(dbGridQuestion.question_id, dbGridQuestion.answer) : null;

  }

}
