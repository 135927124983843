import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

const colorMap = {
    blue: {backgroundColor: 'var(--info-transparent)', color: 'var(--info)'},
    red: {backgroundColor: 'var(--danger-transparent)', color: 'var(--danger)'},
    gray: {backgroundColor: 'var(--basic-250)', color: 'var(--basic-600)'},
    light: {backgroundColor: 'var(--basic-100)', color: 'var(--basic-600)'},
}

@Component({
  selector: 'app-remaining-days-badge',
  templateUrl: './remaining-days-badge.component.html',
  styleUrls: ['./remaining-days-badge.component.scss']
})
export class RemainingDaysBadgeComponent implements OnChanges {
  @Input({required: true}) days!: number;
  @Input() color: 'blue' | 'red' | 'gray' | 'light' = 'gray'

  ngOnChanges(changes: SimpleChanges) {
    if (changes['days']) {
      if (this.days < 0) {
        this.color = 'light'
      } else if (this.days <= 7) {
        this.color = 'red'
      } else {
        this.color = 'gray'
      }
    }
  }

  protected readonly colorMap = colorMap;
}
