import { Component, Input} from '@angular/core';
import { TenderTypesEnum } from "../../models/tender-search-body";

@Component({
  selector: 'app-tender-type-badge',
  templateUrl: './tender-type-badge.component.html',
  styleUrls: ['./tender-type-badge.component.scss']
})
export class TenderTypeBadgeComponent {
  @Input({required: true}) tenderType!: string;

  protected readonly TenderTypesEnum = TenderTypesEnum;

  get badgeBackgroundColor() {
    if (this.tenderType === TenderTypesEnum.ATTRIBUTION) return 'var(--transparent-basic-48)';
    else { return 'var(--basic-250)' }
  }

  get badgeColor() {
    if (this.tenderType === TenderTypesEnum.ATTRIBUTION) return 'var(--basic-100)';
    else { return 'var(--basic-600)' }
  }

  get badgeBorderColor() {
    if (this.tenderType === TenderTypesEnum.ATTRIBUTION) return 'var(--basic-600)';
    else { return '10%' }
  }

}
