import { booleanAttribute, Component, Input } from '@angular/core';
import { TendersSearchService } from '../../services/tenders-search.service';

@Component({
  selector: 'app-tenders-summary',
  templateUrl: './tenders-summary.component.html',
  styleUrls: ['./tenders-summary.component.scss']
})
export class TendersSummaryComponent {
  @Input({required: true}) tendersSearchService!: TendersSearchService;
  @Input({transform: booleanAttribute}) withTime = false;
  @Input({transform: booleanAttribute}) usePaginationCount = false;
  @Input({transform: booleanAttribute}) noPeriodDisplayed = false;

  @Input() iconName?: string;

  getDateTextInfo(): { from: string, to: string } {
    const period = this.tendersSearchService.selectedIndexationPeriod ?? this.tendersSearchService.selectedEstimatedEndPeriod;
    if (!period) return { from: '', to: '' };
    const from = new Date(period.from);
    const to = new Date(period.to);
    return {
      from: from.toLocaleDateString("fr-FR", { weekday: 'short', month: 'numeric', day: 'numeric', year: 'numeric' }),
      to: to.toLocaleDateString("fr-FR", { weekday: 'short', month: 'numeric', day: 'numeric', year: 'numeric' }),
    }
  }

  toToday() {
    return this.tendersSearchService.selectedIndexationPeriod &&
      new Date(this.tendersSearchService.selectedIndexationPeriod.to).getDate() === new Date().getDate();
  }
}
