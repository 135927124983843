import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HeadersService } from '../../../../shared/services/api/headers/headers.service';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import {
  DbGridQuestion,
  DbGridQuestionPostResponseBody,
  GridQuestion,
  GridQuestionTypeEnum,
} from '../../../models/grid-question';
import { environment } from '../../../../../environments/environment';
import { DbGridUserQuestionsPostResponseBody, GridUserQuestion } from '../../../models/grid-user-question';
import { DbGridQuestionWithAnswer, GridQuestionWithAnswer } from '../../../models/grid-question-with-answer';


@Injectable({
  providedIn: 'root'
})
export class ApiSmartGridService {

  constructor(private http: HttpClient,
              private headerService: HeadersService) {
  }

  getTenderGridQuestions(body: any): Observable<GridQuestion[]> {
    return this.http.post<DbGridQuestionPostResponseBody>(
      `${environment.explainApiUrl}reading_grid_questions`,
      body,
      this.headerService.httpHeaders)
      .pipe(map(res => {
        return res.data.map((question) => {
          return new GridQuestion(question);
        });
      }));

  }

  getTenderGridQuestion(questionId: number): Observable<GridQuestion> {
    return this.http.get<DbGridQuestion>(
      `${environment.explainApiUrl}reading_grid_questions?id=${questionId}`,
      this.headerService.httpHeaders)
      .pipe(map(res => {
        return new GridQuestion(res);
      }));

  }

  getUserGridQuestions(body: any): Observable<any[]> {
    return this.http.post<DbGridUserQuestionsPostResponseBody>(
      `${environment.explainApiUrl}user_questions`,
      body,
      this.headerService.httpHeaders)
      .pipe(map(res => {
        const data = res.data;
        return data.map((question) => {
          return new GridUserQuestion(question);
        });
      }));
  }

  putUserGridQuestions(questionId: number): Observable<any> {
    return this.http.put<any>(
      `${environment.explainApiUrl}user_questions`,
      {
        'question_id': questionId,
      },
      this.headerService.httpHeaders);
  }

  deleteUserGridQuestions(questionId: number): Observable<any> {
    return this.http.delete<any>(
      `${environment.explainApiUrl}user_questions?question_id=${questionId}`,
      {
        headers: this.headerService.httpHeaders.headers,
      });
  }

  getTenderGridAnswer(dceProjectUid: string, question_id: number, updated_at: string): Observable<GridQuestionWithAnswer | null> {
    return this.http.post<any>(
      `${environment.explainApiGatewayUrl}reading-grid`,
      {
        project_uid: dceProjectUid,
        question_id: question_id,
        last_updated_at: updated_at
      },
      this.headerService.httpHeaders)
      .pipe(map(res => {
        if (!res) {
          return null;
        }
        if (res['response_body']) {
          res = res['response_body'];
        }
        return new GridQuestionWithAnswer(res as DbGridQuestionWithAnswer);
      }),
      catchError(() => {
        return of(null);
      })
    );
  }

  addGridQuestion(question: string, displayedName: string): Observable<any> {
    return this.http.put<any>(
      `${environment.explainApiGatewayUrl}reading-grid`,
      {
        name: question,
        displayed_name: displayedName,
        type: GridQuestionTypeEnum.PRIVATE
      },
      {
        headers: this.headerService.httpHeaders.headers
      }
    );
  }

  editGridQuestion(questionId: number, question: string): Observable<any> {
    return this.http.put<any>(
      `${environment.explainApiGatewayUrl}reading-grid`,
      {
        question_id: questionId,
        name: question
      },
      {
        headers: this.headerService.httpHeaders.headers
      }
    );
  }

  editGridQuestionDisplayedName(questionId: number, displayedName: string): Observable<any> {
    return this.http.patch<any>(
      `${environment.explainApiUrl}reading_grid_questions`,
      {
        id: questionId,
        displayed_name: displayedName
      },
      {
        headers: this.headerService.httpHeaders.headers
      }
    );
  }

  deleteGridQuestion(questionId: number): Observable<any> {
    return this.http.patch<any>(
      `${environment.explainApiUrl}reading_grid_questions`,
      {
        id: questionId,
        is_active: false
      },
      {
        headers: this.headerService.httpHeaders.headers
      }
    );
  }
}
