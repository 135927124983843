<div *ngIf="guestUser" class="welcome-card">
  <div class="header-card">
    <div class="header-side-part">
      <div class="background-icon rounded-circle">
        <mat-icon svgIcon="icon-person-fill"></mat-icon>
      </div>
      <div class="d-flex flex-column">
        <span class="header-title">{{'welcome-panel.welcome-to-explain' | translate}}</span>
        <span class="header-subtitle">{{guestUser.email}}</span>
      </div>
    </div>
  </div>
  <div class="body-card">
    <div class="collapse-block">
      <div class="collapse-title" (click)="collapse2.toggle()" [attr.aria-expanded]="!isCollapsed2">
        <span>{{'welcome-panel.what-is-explain-ask' | translate}}</span>
      </div>
      <div class="collapse-content" #collapse2="ngbCollapse" [(ngbCollapse)]="isCollapsed2">
        <span>{{'welcome-panel.what-is-explain-1' | translate}}</span>
        <br>
        <span>{{'welcome-panel.what-is-explain-2' | translate}} </span>
        <a class="href-underline" href="https://explain.fr" target="_blank" rel="noopener noreferrer">
          {{'welcome-panel.our-website' | translate}}</a>
        <span>&nbsp;{{'welcome-panel.what-is-explain-3' | translate}}</span>
      </div>
    </div>
    <div class="contact-container">
      <span>{{'welcome-panel.any-other-question' | translate}}</span>
      <a class="href-underline" href="mailto: info@explain.fr">
        {{'welcome-panel.contact-email' | translate}}
      </a>
    </div>
  </div>
</div>
