<div class="tender-filter-container d-flex align-items-center" [ngClass]="{'loading-filters': !userDataLoaded}">

  <!--case text search enabled, ie. in tender search view-->
  <ng-container *ngIf="inTenderSearchView; else onlyTopicsSearch">
    <!--selector between 2 search modes : topics or text-->
    <app-filter-text-and-items
      class="filter-text-and-topics"
      [itemsSectionName]="'thèmes'"
      [items]="selectableItems"
      [placeholder]="'tenders.search-a-tender' | translate"
      (textSelected)="textSelected($event)"
      (itemSelected)="topicSelected($event)"
      openAfterInit
    >
    </app-filter-text-and-items>
  </ng-container>

  <!--case only topics, ie. in market watch views-->
  <ng-template #onlyTopicsSearch>
    <app-filter-topic
      *ngIf="!filters || filters.includes(FilterType.TOPIC)"
      [items]="tenderTopics || []"
      [defaultTopicIds]="defaultTopicIds"
      (itemsSelected)="topicsSelected($event)"
    />
  </ng-template>

  <!--other filters are common-->
  <app-filter-date
    [displayedEstimatedEndPeriod]="inTenderSearchView"
    [ngClass]="{'lg': inTenderSearchView}"
    [tenderSearchService]="tenderSearchService"
    *ngIf="!filters || filters.includes(FilterType.PERIOD)"
    (periodSelected)="periodSelected($event)"
    [defaultPeriod]="defaultPeriod ? defaultPeriod : undefined"
  />
  <app-filter-territory
    *ngIf="!filters || filters.includes(FilterType.TERRITORY)"
    [items]="territoryItems"
    [defaultTerritories]="defaultTerritoriesIds"
    [alternativeDisplay]="alternativeDisplay"
    [selectionCount]="territorySelectionCount"
    [maxCount]="totalCount"
    (territoriesSelected)="territoriesSelected($event)"
  />
  <app-filter-type
    *ngIf="!filters || filters.includes(FilterType.TYPE)"
    [defaultTypes]="defaultTypes"
    (typesSelected)="typesSelected($event)"
  />
</div>
