import { Component, Input, OnInit } from '@angular/core';
import { User } from '../../../models/user';
import { ApiService } from '../../../shared/services/api/api.service';

@Component({
  selector: 'app-welcome-panel',
  templateUrl: './welcome-panel.component.html',
  styleUrls: ['./welcome-panel.component.scss']
})

export class WelcomePanelComponent implements OnInit {
  @Input() shareToken: string = "";
  isCollapsed2 = false;
  guestUser: User | null = null;

  constructor(
    private apiService: ApiService
  ) { }

  ngOnInit(): void {
    this.apiService.share.getGuestUser(this.shareToken).subscribe(
      (user: User) => { // success
        this.guestUser = user;
        localStorage.setItem('email', '[guest]' + user?.email);
        localStorage.setItem('user_id', String(user?.id));
        localStorage.setItem('group_account_id', String(user?.groupAccountId));
      },
      () => { // error
        this.guestUser = {email: 'email non trouvé', id: 0, groupAccountId: 0} as unknown as User;
      });
  }

}
