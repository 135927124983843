import { DbGridAnswer, GridAnswer } from './grid-answer';
import { DbDceDocumentUid } from './dce-document-uid';
import { Pagination } from '../../models/generic/pagination';


export enum GridQuestionTypeEnum {
  PUBLIC = 'public',
  PRIVATE = 'private',
  SUMMARY = 'summary',
}

export enum GridQuestionSectionEnum {
  SUMMARY = 'summary',
  PINNED = 'pinned',
  UNPINNED = 'unpinned',
}

export enum GridQuestionFormActionEnum {
  ADD = 'add',
  EDIT = 'edit'
}

export interface DbGridQuestionPostResponseBody {
  data: DbGridQuestion[];
  pagination: Pagination;
}

export interface DbGridQuestion {
  project_uid: string;
  id: number;
  displayed_name: string;
  name: string;
  documents_uids: DbDceDocumentUid[];
  answer: DbGridAnswer | null;
  is_generation_finished: boolean;
  type: GridQuestionTypeEnum,
  is_generation_failed: boolean;
  updated_at: string;
  user_id: number | null;
}

export class GridQuestion {
  questionId: number;
  displayedName: string;
  name: string;
  isGenerationFinished: boolean;
  type?: GridQuestionTypeEnum;
  isGenerationFailed: boolean;
  updated_at: string | null = null;
  answer: GridAnswer | null;
  user_id: number | null = null;
  ownerFirstname: string | null = null;
  ownerLastname: string | null = null;

  constructor(dbGridQuestion: DbGridQuestion) {
    this.questionId = dbGridQuestion.id;
    this.displayedName = dbGridQuestion.displayed_name;
    this.name = dbGridQuestion.name;
    this.isGenerationFailed = dbGridQuestion.is_generation_failed ?? false;
    this.isGenerationFinished = dbGridQuestion.is_generation_finished ?? false;
    this.answer = dbGridQuestion.answer ? new GridAnswer(dbGridQuestion.id, dbGridQuestion.answer) : null;
    this.type = dbGridQuestion.type;
    this.updated_at = dbGridQuestion.updated_at;
    this.user_id = dbGridQuestion.user_id;
  }
}
