<mat-expansion-panel [ngClass]="{
                      'right-border-entity': true,
                      'margin-bottom-16': true,
                      'full-width': !withExportPanel || sharePanel.expanded,
                      'mid-width': withExportPanel && !sharePanel.expanded
                     }"
                     class="no-select"
                     data-cy="share-panel-mat-expansion"
                     expanded="false"
                     hideToggle="true"
                     (click)="toggle.emit(sharePanel.expanded)"
                     #sharePanel
>
  <mat-expansion-panel-header data-cy="sharing-panel-expansion-header-button" class="right-border-entity-header">
    <mat-panel-title>
      <div class="header-content">
        <div class="header-left-content">
          <mat-icon class="icon-share" svgIcon="share-white"></mat-icon>
          <div class="title">{{'share.panel-header' | translate | uppercase}}</div>
        </div>
      </div>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div *ngIf="sharePanel.expanded">
    <app-sharing-panel
      [module]="ExplainModuleEnum.TERRITORY_INTEL"
      [document]="document"
      (cancel)="toggle.emit(sharePanel.expanded = false)"
      (share)="toggle.emit(sharePanel.expanded = false)"
    ></app-sharing-panel>
  </div>
</mat-expansion-panel>
