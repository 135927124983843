import { Pagination } from '../../models/generic/pagination';

export interface DbGridUserQuestion {
  id: number;
  question_id: number;
  user_id: number;
}

export interface DbGridUserQuestionsPostResponseBody {
  data: DbGridUserQuestion[];
  pagination: Pagination;
}

export class GridUserQuestion {
  question_id: number;
  user_id: number;

  constructor(dbGridUserQuestion: DbGridUserQuestion) {
    this.question_id = dbGridUserQuestion.question_id;
    this.user_id = dbGridUserQuestion.user_id;
  }
}
