import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, interval } from 'rxjs';
import { finalize, takeWhile } from 'rxjs/operators';

import {
  GridQuestionSectionEnum,
  GridQuestionTypeEnum,
  GridQuestion,
} from '../models/grid-question';
import { TextItem } from '../models/grid-answer';
import { ApiTenderService } from './api/api-tender.service';
import { ApiService } from '../../shared/services/api/api.service';
import { PostGridQuestionSearchBody } from '../models/grid-question-search-body';
import { GridQuestionWithAnswer } from '../models/grid-question-with-answer';

@Injectable()
export class SmartGridService {
  private dceProjectUid!: string;

  private maxApiCalls = 10;
  private apiCallsInterval = 2000;
  public MAX_PRIVATE_QUESTIONS = 100;
  public MAX_PINNED_QUESTIONS = 15;


  public _unpinnedQuestion$ = new BehaviorSubject<{ data: GridQuestion[], ready: boolean } | null>(null);
  public _pinnedQuestion$ = new BehaviorSubject<{ data: GridQuestion[], ready: boolean } | null>(null);
  public _summaryQuestion$ = new BehaviorSubject<{ data: GridQuestion, ready: boolean } | null>(null);

  constructor(
    private apiTenderService: ApiTenderService,
    private apiService: ApiService,
  ) {
  }

  setProjectUid(uid: string): void {
    this.dceProjectUid = uid;
  }

  async fetchQuestion(questionId: number): Promise<GridQuestion> {
    const question = await firstValueFrom(this.apiTenderService.gridQuestion.getTenderGridQuestion(questionId));

    if (this.pinnedQuestions.map(q => q.questionId).includes(questionId)) {
      this.updatePinnedQuestion(question);
    } else if (this.unpinnedQuestions.map(q => q.questionId).includes(questionId)) {
      this.updateUnpinnedQuestion(question);
    }
    return question;
  }


  async fetchQuestions(type?: GridQuestionTypeEnum, section?: GridQuestionSectionEnum): Promise<void> {
    if (!this.dceProjectUid) {
      throw new Error('dceProjectUid is not set. Call setProjectUid(uid) before fetching questions.');
    }
    const searchBody = new PostGridQuestionSearchBody(type, 1000);
    try {
      let questions = await firstValueFrom(
        this.apiTenderService.gridQuestion.getTenderGridQuestions(searchBody)
      );

      if (type === GridQuestionTypeEnum.SUMMARY && questions.length > 0) {
        this._summaryQuestion$.next({data: questions[0], ready: true});
      } else {
        questions = questions.filter((question) => question.type !== GridQuestionTypeEnum.SUMMARY);
        let userQuestions = await firstValueFrom(
          this.apiTenderService.gridQuestion.getUserGridQuestions({
            filters: {},
            limit: 25,
          })
        );

        questions.sort((a, b) => a.displayedName.localeCompare(b.displayedName));
        const userQuestionsIds = userQuestions.map((question) => question.question_id);

        const pinnedQuestions = questions.filter((question) => userQuestionsIds.includes(question.questionId));
        const unpinnedQuestions = questions.filter((question) => !(userQuestionsIds.includes(question.questionId)));

        if (section === GridQuestionSectionEnum.UNPINNED || !section) {
          this._unpinnedQuestion$.next({data: unpinnedQuestions, ready: true});
        }
        if (section === GridQuestionSectionEnum.PINNED || !section) {
          this._pinnedQuestion$.next({data: pinnedQuestions, ready: true});
        }
      }

    } catch (e) {
      console.error('Error while fetching grid questions', e);
    }

  }

  async fetchAnswers(type: GridQuestionSectionEnum): Promise<void> {
    if (type === GridQuestionSectionEnum.SUMMARY && this.summaryQuestion) {
      await this._fetchAnswers([this.summaryQuestion], GridQuestionSectionEnum.SUMMARY);
    } else if (type === GridQuestionSectionEnum.PINNED && this.pinnedQuestions) {
      await this._fetchAnswers(this.pinnedQuestions, GridQuestionSectionEnum.PINNED);
    } else if (type === GridQuestionSectionEnum.UNPINNED && this.unpinnedQuestions) {
      await this._fetchAnswers(this.unpinnedQuestions, GridQuestionSectionEnum.UNPINNED);
    }
  }

  async _fetchAnswers(questions: GridQuestion[], type: GridQuestionSectionEnum): Promise<void> {
    const fetchPromises = questions
      .map(async question => {
        const questionWithAnswer = await this.fetchAnswer(question);
        this._updateQuestionWithAnswer(questionWithAnswer, type);
      });

    await Promise.all(fetchPromises);
  }

  async fetchAnswer(question: GridQuestion): Promise<GridQuestion> {
    const initTime = Date.now();
    let numberApiCalls = 0;

    return new Promise((resolve) => {
      interval(this.apiCallsInterval)
        .pipe(
          takeWhile(() => !question.isGenerationFinished && numberApiCalls < this.maxApiCalls),
          finalize(() => {
            question.isGenerationFinished = true;
            console.log(`Generation time: ${Date.now() - initTime} ms`);
          })
        )
        .subscribe(async () => {
          let res: GridQuestionWithAnswer | null = null;
          if (question.updated_at) {
            res = await firstValueFrom(
              this.apiTenderService.gridQuestion.getTenderGridAnswer(this.dceProjectUid, question.questionId, question.updated_at)
            );
          }
          if (res) {
            if (res.isGenerationFinished) {
              if (question.type === GridQuestionTypeEnum.SUMMARY) {
                res.answer = this.updateQuestionsWithRefs(res.answer);
              }
              question.answer = res.answer;
              question.isGenerationFailed = res.isGenerationFailed;
              question.isGenerationFinished = res.isGenerationFinished;

              resolve(question);
            } else {
              numberApiCalls++;
            }
          } else {
            question.isGenerationFinished = true;
            resolve(question);
          }
        });
    });
  }


  parseTextWithRefs(input: string): TextItem[] {
    const result: TextItem[] = [];
    const regex = /(?:([\s\S]+?)(\[(?:(?:[a-f0-9]{64})(?:,\s*)?)+\]))|([\s\S]+)/g;

    let match;
    let refIndex = 0;

    while ((match = regex.exec(input)) !== null) {
      const text = match[1];
      if (text) {
        result.push({text});
      }

      const refs = match[2];
      if (refs) {
        const refRegex = /([a-f0-9]{64})/g;
        let refMatch;

        while ((refMatch = refRegex.exec(refs)) !== null) {
          result.push({ref: refMatch[1], index: refIndex});
          refIndex++;
        }
      }

      const remainingText = match[3];
      if (remainingText) {
        result.push({text: remainingText});
      }
    }

    return result;
  }

  updateQuestionsWithRefs(answer: any): any {
    if (answer?.text) {
      answer.textWithRefs = this.parseTextWithRefs(answer.text);
    }
    return answer;
  }

  async fetchQuestionInfo(question: GridQuestion, type: GridQuestionSectionEnum): Promise<void> {
    if (question.user_id && question.type === GridQuestionTypeEnum.PRIVATE) {
      if (question.ownerFirstname && question.ownerLastname) {
        return;
      }
      question = await this._fetchQuestionUser(question);
      if (type === GridQuestionSectionEnum.PINNED && this.pinnedQuestions) {
        this._pinnedQuestion$.next({
          data: this.pinnedQuestions?.map((q) => {
            if (q.questionId === question.questionId) {
              q = question;
            }
            return q;
          }), ready: true
        });

      } else if (type === GridQuestionSectionEnum.UNPINNED && this.unpinnedQuestions) {
        this._unpinnedQuestion$.next({
          data: this.unpinnedQuestions?.map((q) => {
            if (q.questionId === question.questionId) {
              q = question;
            }
            return q;
          }), ready: true
        });
      }
    }
  }

  async _fetchQuestionUser(question: GridQuestion): Promise<GridQuestion> {
    if (question.user_id) {
      const user = await firstValueFrom(this.apiService.account.getUserInfo(question.user_id));
      if (user) {
        question.ownerFirstname = user.firstName;
        question.ownerLastname = user.lastName;
      }
    }
    return question;
  }

  get unpinnedQuestions() {
    return this._unpinnedQuestion$.value?.data || [];
  }

  set unpinnedQuestions(questions: GridQuestion[]) {
    this._unpinnedQuestion$.next({data: questions, ready: true});
  }

  get pinnedQuestions() {
    return this._pinnedQuestion$.value?.data || [];
  }

  set pinnedQuestions(questions: GridQuestion[]) {
    this._pinnedQuestion$.next({data: questions, ready: true});
  }

  get summaryQuestion() {
    return this._summaryQuestion$.value?.data;
  }

  get pinnedQuestionsReady() {
    return this._pinnedQuestion$.value?.ready;
  }

  get unpinnedQuestionsReady() {
    return this._unpinnedQuestion$.value?.ready;
  }

  checkIfTenderOpenedAfterLimitDate() {
    return (this.pinnedQuestions.every((question) =>
        !question.answer && question.isGenerationFinished && !question.isGenerationFailed)
      && (this.unpinnedQuestions.every((question) =>
        !question.answer && question.isGenerationFinished && !question.isGenerationFailed)))
  }

  updateUnpinnedQuestion(updatedQuestion: GridQuestion): void {
    const unpinnedQuestions = this.unpinnedQuestions || [];
    const index = unpinnedQuestions.findIndex((q) => q.questionId === updatedQuestion.questionId);
    if (index !== -1) {
      unpinnedQuestions[index] = updatedQuestion;
      unpinnedQuestions.sort((a, b) => a.displayedName.localeCompare(b.displayedName));
      this._unpinnedQuestion$.next({data: unpinnedQuestions, ready: true});
    }
  }

  updatePinnedQuestion(updatedQuestion: GridQuestion): void {
    const pinnedQuestions = this.pinnedQuestions || [];
    const index = pinnedQuestions.findIndex((q) => q.questionId === updatedQuestion.questionId);
    if (index !== -1) {
      pinnedQuestions[index] = updatedQuestion;
      pinnedQuestions.sort((a, b) => a.displayedName.localeCompare(b.displayedName));
      this._pinnedQuestion$.next({data: pinnedQuestions, ready: true});
    }
  }


  _updateQuestionWithAnswer(updatedQuestion: GridQuestion, type: GridQuestionSectionEnum): void {
    if (type === GridQuestionSectionEnum.PINNED) {
      this.updatePinnedQuestion(updatedQuestion);
    } else if (type === GridQuestionSectionEnum.UNPINNED) {
      this.updateUnpinnedQuestion(updatedQuestion);
    } else if (type === GridQuestionSectionEnum.SUMMARY) {
      this._summaryQuestion$.next({data: updatedQuestion, ready: true});
    }
  }

  getSumOfPrivateQuestions(): number {
    const privatePinnedQuestions = this.pinnedQuestions?.filter((question) => question.type === GridQuestionTypeEnum.PRIVATE);
    const privateUnpinnedQuestions = this.unpinnedQuestions?.filter((question) => question.type === GridQuestionTypeEnum.PRIVATE);
    return (privatePinnedQuestions?.length || 0) + (privateUnpinnedQuestions?.length || 0);
  }

  async pinQuestion(question: GridQuestion): Promise<void> {
    await firstValueFrom(this.apiTenderService.gridQuestion.putUserGridQuestions(question.questionId));
    this.pinnedQuestions = [...this.pinnedQuestions, question].sort((a, b) => a.displayedName.localeCompare(b.displayedName));
    this._pinnedQuestion$.next({data: this.pinnedQuestions, ready: true});

    this.unpinnedQuestions = this.unpinnedQuestions.filter((q) => q.questionId !== question.questionId);
    this._unpinnedQuestion$.next({data: this.unpinnedQuestions, ready: true});

    question = await this.fetchAnswer(question);
    this.updatePinnedQuestion(question);

  }

  async unpinQuestion(question: GridQuestion): Promise<void> {
    await firstValueFrom(this.apiTenderService.gridQuestion.deleteUserGridQuestions(question.questionId));
    this.unpinnedQuestions = [...this.unpinnedQuestions, question].sort((a, b) => a.displayedName.localeCompare(b.displayedName));
    this._unpinnedQuestion$.next({data: this.unpinnedQuestions, ready: true});

    this.pinnedQuestions = this.pinnedQuestions.filter((q) => q.questionId !== question.questionId);
    this._pinnedQuestion$.next({data: this.pinnedQuestions, ready: true});
  }

  async editQuestion(questionToEdit: GridQuestion, name: string, displayedName: string): Promise<void> {
    try {
      if (questionToEdit.displayedName !== displayedName) {
        await firstValueFrom(this.apiTenderService.gridQuestion.editGridQuestionDisplayedName(questionToEdit.questionId, displayedName));
      }
      if (questionToEdit.name !== name) {
        await firstValueFrom(this.apiTenderService.gridQuestion.editGridQuestion(questionToEdit.questionId, name));
      }
    } catch (e) {
      console.error('Error while editing question', e);
      throw e;
    }
  }

  async deleteQuestion(questionId: number): Promise<void> {
    try {
      await firstValueFrom(this.apiTenderService.gridQuestion.deleteGridQuestion(questionId));
    } catch (e) {
      console.error('Error while deleting question', e);
      throw e;
    }
  }

  async getNumberOfUsersPinningQuestion(questionId: number | null): Promise<number> {
    if (!questionId) {
      return 0;
    }
    const userQuestions = await firstValueFrom(
      this.apiTenderService.gridQuestion.getUserGridQuestions({
        fields: ['question_id', 'user_id'],
        filters: {
          question_id: questionId,
        },
        limit: 25,
      })
    );
    const user_id = localStorage.getItem('user_id');
    let isUserPinning = false;
    if (user_id) {
      isUserPinning = userQuestions.map((question) => question.user_id).includes(parseInt(user_id));
    }
    return userQuestions.length - (isUserPinning ? 1 : 0);
  }
}
