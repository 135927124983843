import { Tender } from '../../models/tender';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { TendersFilters, TendersSearchService } from '../../services/tenders-search.service';
import { AutoUnsubscribe } from '../../../common-explain/decorators/auto-unsubscribe';
import { TenderEntityService } from "../../services/tender-entity.service";
import { Router } from "@angular/router";
import { TendersModuleService } from "../../services/tenders-module.service";
import { FilterType } from "../tenders-filter/tenders-filter.component";
import { DbPeriod } from "../filter-date/filter-date.component";
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { SortDirEnum } from "../../../common-explain/models/sort";
import { TendersExportService } from '../../services/tenders-export.service';
import { SortFieldEnum } from "../../models/tender-search-body";

@Component({
  selector: 'app-tenders-search',
  templateUrl: './tenders-search.component.html',
  styleUrls: ['./tenders-search.component.scss'],
  providers: [TendersExportService]
})
@AutoUnsubscribe
export class TendersSearchComponent implements OnInit, OnDestroy {
  protected readonly FilterType = FilterType;
  tenders?: Tender[] = new Array<Tender>();
  tenderId = '';
  totalCount = 0;
  currentPage = 1;
  showFilters = false;
  defaultPeriod: DbPeriod;

  constructor(public tenderSearchService: TendersSearchService,
              protected tendersExportService: TendersExportService,
              private tenderEntityService: TenderEntityService,
              private tendersModuleService: TendersModuleService,
              public router: Router
  ) {
    const lastMonth = this.getLastMonthDate();
    this.defaultPeriod = {
      from: new NgbDate( lastMonth.getFullYear(), lastMonth.getMonth() + 1, lastMonth.getDate() ),
      to: new NgbDate( new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate() )
    }
    // Update tenderTopics in case of changes
    this.tendersModuleService.initTenderTopics();
  }

  async ngOnInit() {
    this.tendersModuleService.registerService(this.tenderSearchService);
    // set default sorting for search
    this.tenderSearchService._filters$.next({...(this.tenderSearchService._filters$.value ?? {}),
      sort: {field: SortFieldEnum.RELEVANCE, dir: SortDirEnum.DESC}, offset: 0, propagate: false, fromFilter: undefined});
    this.tenderEntityService.tenderId$
      .subscribe((tenderId) => {
        this.tenderId = tenderId;
      });

    this.tenderSearchService._tenders$
      .subscribe((res) => {
        this.updateSearchValues(res?.data, res?.total_count);
      });
  }

  ngOnDestroy() {
    this.tenderSearchService.resetSubjects();
    this.tendersModuleService.unregisterService(this.tenderSearchService);
  }

  get inSearch() {
    return this.tenderSearchService.inSearch;
  }

  get nbPages() {
    return this.tenderSearchService.nbPages;
  }

  updateSearchValues(tenders?: Tender[], totalCount?: number) {
    this.tenders = tenders;
    this.totalCount = totalCount ?? 0;
    this.tenderSearchService.nbPages = (Math.ceil(this.totalCount / this.tenderSearchService.limit));
  }

  get filters(): TendersFilters {
    return this.tenderSearchService._filters$.value ?? {};
  }

  getLastMonthDate() {
    const lastMonthDate = new Date();
    lastMonthDate.setMonth(lastMonthDate.getMonth() - 1);
    return lastMonthDate;
  }

  onRefineResults($event: string) {
    this.tenderSearchService.refinedSearchText = $event;
  }
}
