<div class="tender-pdf-viewer h-100 position-relative">
  <app-simple-loader
    class="tender-detail-simple-loader"
    [isShown]="showLoader">
  </app-simple-loader>

  <!--première ligne d'outils - indépendants du lecteur pdf-->
  <div class="first-line-container">
    <div class="first-line d-flex">

      <!-- sélecteur de document pdf -->
      <div class="tender-pdfs-dropdown-container"
           *ngIf="this.tenderDetail.dceDocuments.length">
        <ex-dropdown
          noInput
          boldInputText
          [placeholder]="dropdownPlaceholder"
          (dropdownToggle)="dropdownToggle($event)"
          shadowBorder
        >

          <ng-template exDropdownContent>
            <div class="tenders-dropdown-content">

              <!--vue étendue-->
              <ex-tree-node *ngIf="dropdownMode === 'expanded'"
                            [node]="tenderDetail.dceTree"
                            [template]="template">
              </ex-tree-node>

              <!--vue condensée-->
              <ex-tree-node *ngIf="dropdownMode === 'condensed'"
                            [node]="tenderDetail.condensedDceTree"
                            [template]="template">
              </ex-tree-node>

              <ng-template #template exDropdownItem
                           let-item>

                <!--utilisation de rawKey pour identifier l'item-->
                <div *ngIf="(item.value?.rawKey === tenderDetail.dceDocuments[0]?.rawKey) &&
                          Object.values(TenderAvisType).includes(item.value?.publicationType)"
                     class="tree-header header-1">
                  {{ 'tenders.tree-header.avis' | translate | uppercase }}
                </div>

                <div *ngIf="item.name !== 'root' && item.value.visible"
                     class="tree-node no-select"
                     [ngClass]="{
                        'folder': item.children,
                        'document': !item.children,
                        'selected': item.value?.uid === document.uid,
                        'no-preview-file': item.value.itemType === 'no-preview-file',
                        'unknown-file': item.value.itemType === 'unknown-file'
                     }"
                     (click)="forbiddenItemTypes.includes(item.value.itemType) ? '' : itemSelected(item, $event)">
                  <mat-icon class="document-icon"
                            [svgIcon]="item.children ? (item.value.expanded ? 'folder-minus' : 'folder-plus') : item.value.iconName "></mat-icon>
                  <!--Cas d'un itemType document connu ou dossier : on a le overflowTooltip-->
                  <div *ngIf="!forbiddenItemTypes.includes(item.value.itemType)"
                       class="doc-name pdl-4px pdr-4px"
                       [ngbTooltip]="item.name"
                       placement="bottom"
                       container="body"
                       tooltipClass="custom-tooltip-tender-name"
                       [openDelay]="500"
                       [closeDelay]="0"
                       overflowTooltip>
                    {{ item.name }}
                  </div>
                  <!--Cas d'un itemType non connu ou sans previalisation : on a pas le overflowTooltip-->
                  <div *ngIf="forbiddenItemTypes.includes(item.value.itemType)"
                       class="{{item.value.itemType}} doc-name pdl-4px pdr-4px"
                       [ngbTooltip]="('tenders.tree.' + item.value.itemType | translate)"
                       placement="bottom"
                       container="body"
                       tooltipClass="custom-tooltip-tender-name"
                       [openDelay]="500"
                       [closeDelay]="0">
                    {{ item.name }}
                  </div>
                  <!--cas document de la grille d'analyse - exceptés les avis-->
                  <div class="document-icon-container d-flex flex-grow-1">
                    <div class="flex-grow-1"></div>
                    <mat-icon *ngIf="item.value?.isSmartGridDoc &&
                              !item.children &&
                              !Object.values(TenderAvisType).includes(item.value?.publicationType)"
                              svgIcon="disk"
                              class="smart-file"
                              [ngbTooltip]="smartGridTooltip"
                              tooltipClass="custom-tooltip-smart-grid-indicator"
                              placement="bottom"
                              container="body"/>
                    <i *ngIf="item.value.publicationType !== 'ao_avis_source'"
                       class="download-file ph-bold ph-download-simple"
                       (click)="$event.stopPropagation();downloadFile(item.value.uid)"></i>
                  </div>

                  <ng-template #smartGridTooltip>
                    <div class="smart-grid-tooltip d-flex flex-column">
                      <div class="title">{{ 'tenders.smart-grid.tooltip-title' | translate }}</div>
                      <div class="body">{{ 'tenders.smart-grid.tooltip-body' | translate }}</div>
                    </div>
                  </ng-template>

                </div>

                <div *ngIf="(item.value?.rawKey === tenderDetail.dceDocuments[numberOfAvisToDisplay - 1]?.rawKey) &&
                          Object.values(TenderAvisType).includes(item.value?.publicationType)"
                     class="tree-header header-2">

                  {{ dceSectionText()| translate | uppercase }}

                  <span
                    *ngIf="tenderDetail.dceProjectUid && tenderDetail.strictSmartDocumentNumber && dropdownMode==='condensed'"
                    class="ai-selection">
                        ({{ 'tenders.tree-header.ai-selection' | translate | uppercase }})
                  </span>
                  <span *ngIf="tenderDetail.dceProjectUid && !tenderDetail.strictSmartDocumentNumber"
                        [ngClass]="{'not-available': !tenderDetail.strictSmartDocumentNumber}"
                        class="ai-selection">
                        ({{ 'tenders.tree-header.ai-selection-not-available' | translate | uppercase }})
                  </span>

                </div>

              </ng-template>
            </div>

          </ng-template>

          <ng-template exDropdownFooter>
            <div *ngIf="tenderDetail.dceProjectUid && tenderDetail.strictSmartDocumentNumber"
                 class="dropdown-mode-switch-container" (click)="dropdownModeSwitchClick($event)">
              <div
                class="text">{{ (dropdownMode === 'expanded' ? 'tenders.dropdown-menu.collapse' : 'tenders.dropdown-menu.expand') | translate }}
              </div>
              <div class="flex-grow-1"></div>
              <div>
                <mat-icon *ngIf="dropdownMode === 'expanded'" svgIcon="chevron-up"/>
                <mat-icon *ngIf="dropdownMode === 'condensed'" svgIcon="chevron-down"/>
              </div>
            </div>
          </ng-template>


        </ex-dropdown>

      </div>

      <!--boutons d'action-->
      <div class="d-flex flex-grow-1 align-items-center gap-2"
           *ngIf="this.tenderDetail.dceDocuments.length">
        <!-- séparateur -->
        <div class="separator flex-grow-1"></div>

        <!-- accéder au profil acheteur -->
        <div *ngTemplateOutlet="accessToBuyerProfile"></div>

        <!-- télécharger le DCE -->
        <ng-container *ngTemplateOutlet="downloadDce" />
      </div>

    </div>
  </div>

  <div class="lecteur-pdf position-relative" id="pdfsViewer">
    <ngx-extended-pdf-viewer
      class="tender-pdfs-viewer"
      *ngIf="tenderDetail.dceDocuments.length"
      backgroundColor="var(--basic-250)"
      [hidden]="documentHasToBeHidden(document)"
      [src]="document.previewUrl"
      [height]="'auto'"
      [zoom]="'auto'"
      (pageRendered)="manageDrawingBoundingBox()"
      [(page)]="page"
      [useBrowserLocale]="true"
      [textLayer]="true"
      [sidebarVisible]="false"
      [customToolbar]="customToolbar"
      [handTool]="false"
      [ignoreKeyboard]="true"
      [enablePrint]="false"
      (pagesLoaded)="onPagesLoaded($event)"
      (updateFindMatchesCount)="updateFindMatchesCount($event)"
      (updateFindState)="updateFindState($event)"
      (pdfLoadingFailed)="pdfLoadingFailed($event)"
    >
    </ngx-extended-pdf-viewer>

    <!--placeholder pour formats interdits-->
    <div *ngIf="documentHasToBeHidden(document)"
         class="html-document-placeholder w-100 d-flex flex-column align-items-center justify-content-center">
      <div class="line-1 align-items-center justify-content-center">
        {{ 'tenders.tender-detail.forbidden-doc-placeholder.line-1' | translate }}
      </div>
      <div class="line-2 align-items-center justify-content-center">
        {{ 'tenders.tender-detail.forbidden-doc-placeholder.line-2' | translate }}
      </div>
      <!-- accéder au profil acheteur -->
      <div *ngTemplateOutlet="accessToBuyerProfile"></div>
    </div>

    <div *ngIf="searchNotFound" class="h-100 w-100 d-flex flex-column align-items-center justify-content-center">
      <app-simple-loader
        class="pdf-reader-simple-loader"
        [isShown]="false"
        [noResult]="searchNotFound">
      </app-simple-loader>
    </div>

  </div>

</div>

<!--second ligne d'outils - custom toolbar du lecteur pdf-->
<ng-template #customToolbar>
  <div class="custom-toolbar-container">

    <div class="first-line"></div>

    <div *ngIf="tenderDetail.dceDocuments.length && !showLoader"
         class="second-line d-flex">

      <!-- séparateur -->
      <div class="flex-grow-1"></div>

      <!-- téléchargement -->
      <i class="download-file ph-bold ph-download-simple ex-shadow" (click)="downloadFile(document.uid)"></i>

      <!-- ctrl + f -->
      <div class="control-f-container ">
        <app-control-f
          class="w-100 d-flex flex-grow-1 align-items-center justify-content-center
                 ctrl-f"
          (onSearch)="searchtext = $event"
          (onNext)="findNext()"
          (onPrev)="findPrevious()"
          [inputValue]="searchtext"
          [documentLoaded]="true"
          [display]="'compact'"
          [totalMatches]="totalMatches"
          [placeholderForTranslate]="'tenders.pdf-reader-placeholder'"
          [styleMode]="'light'"
          [inputBackgroundColor]="'grey'"
          useShadow
        />
      </div>

      <!-- input de page -->
      <div class="page-number-input-container d-flex justify-content-center align-items-center ex-shadow">
        <div class="rounded-bordered-element d-flex">
          <input *ngIf="nbPages"
                 type="text"
                 [(ngModel)]="page">
          <div class="nb-total-pages">/{{ nbPages }}</div>
        </div>
      </div>

    </div>

  </div>
</ng-template>

<!--partie relative aux toast d'export-->
<ng-template #exportInProgressToast let-template="template" let-title="title" let-toast="toast" let-toastService="toastService">
  <div class="awaiting-toast-container no-select">
    <div class="left-part-toast">
      <div class="align-middle justify-content-center">
        <div class="waiting-circle-full"></div>
      </div>
    </div>
    <div class="center-part-toast">
      <div class="title color-primary-500 fw-bold">
        <span
          class="d-table-cell align-middle">{{ title | translate | uppercase }}</span>
      </div>
      <div class="text fst-italic fw-bold color-basic-800">
        <span class="d-table-cell align-middle">{{ 'tenders.export.awaiting-export-toast-text' | translate }}</span>
      </div>
      <div class="cancel-export color-basic-600 fw-bold d-table">
        <span class="d-table-cell align-middle"
              (click)="toastService.remove(toast); stopExportAndRemoveExportToast();">{{ 'export.awaiting-export-toast-cancel' | translate }}</span>
      </div>
    </div>
    <div class="right-part-toast">
      <div class="hide-icon"
           ngbTooltip="{{'export.hide' | translate}}"
           placement="top">
        <mat-icon svgIcon="hide-600" class="icon-20 strict me-2"
                  (click)="toastService.hide(toast,
                                             template,
                                            'toast-export-in-progress toast-shape ' + ('t-e-i-p-' + tenderDetail.id),
                                             tenderDetail.id,
                                             lightToast)"
        >
        </mat-icon>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #DceExportToast let-toast="toast" let-toastService="toastService">
  <ng-container [ngTemplateOutlet]="exportInProgressToast"
                [ngTemplateOutletContext]="{
                  toast, toastService, title: 'tenders.export.dce-export-toast-title', template: DceExportToast
                }">
  </ng-container>
</ng-template>
<ng-template #FileExportToast let-toast="toast" let-toastService="toastService">
  <ng-container [ngTemplateOutlet]="exportInProgressToast"
                [ngTemplateOutletContext]="{
                  toast, toastService, title: 'tenders.export.file-export-toast-title', template: FileExportToast
                }">
  </ng-container>
</ng-template>

<ng-template #lightToast let-toast="toast" let-toastService="toastService">
  <div class="mini-toast-container d-flex">
    <div class="separator"></div>
    <div class="mini-toast" (click)="toastService.unhide(toast, tenderDetail.id)">
      <div class="mini-waiting-circle d-table-cell align-middle align-content-center">
        <div class="waiting-circle-mini"></div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #errorInExport>
  <app-toast-error
    [title]="'export.export-error-title'"
    [text]="'export.export-error-text'">
  </app-toast-error>
</ng-template>

<ng-template #accessToBuyerProfile>
  <a *ngIf="tenderDetail.dceUrl" [href]="tenderDetail.dceUrl" target="_blank" rel="noreferrer"
     (click)="trackUrlClick()">
    <ex-button icon="globe" variant="ghost">
      {{ 'tenders.metadata.external-link' | translate }}
    </ex-button>
  </a>
  <div *ngIf="!tenderDetail.dceUrl"
       class="no-dce-url pdf-custom-bar-button">{{ 'tenders.unset-values.dce-url' | translate }}
  </div>
</ng-template>

<ng-template #downloadDce>
  <ex-button *ngIf="tenderDetail.dceProjectUid" icon="box-arrow-down-fill" variant="ghost" (click)="onDownloadDce()">
    {{ 'tenders.dce-download' | translate }}
  </ex-button>
  <div *ngIf="!tenderDetail.dceProjectUid"
       class="no-dce pdf-custom-bar-button">{{ 'tenders.no-dce' | translate }}
  </div>
</ng-template>
